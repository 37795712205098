<template>
  <div id="app"  :class="[this.darkMode ? 'darkMode' : 'lightMode']">

    <img alt="Legi logo" src="./assets/logo.png" style="float: left; width: 15%; margin:2%;">
    <div class="menu">
      

      <select v-model="readingTime" style="margin-right: 2vw; font-size: min(8vw, 16px)">
        <option value="5">5 min</option>
        <option value="10">10 min</option>
        <option value="15">15 min</option>
        <option value="20">20 min</option>
        <option value="25">25 min</option>
        <option value="30">30 min</option>
      </select>

      <select v-model="langValue" style="margin-right: 2vw; font-size: min(8vw, 16px)">
        <option value="es-ES">{{$t("spanish")}}</option>
        <option value="en-US">{{$t("english")}}</option>
        <option value="fr-FR">{{$t("french")}}</option>
      </select>

      <i @click="getStory" v-bind:title="$t('get_story')" aria-hidden="true" :class="['fas', 'fa-book-open', 'menuicon', this.darkMode ? 'darkMode' : 'lightMode']"></i>
      <i v-if="this.audio && !this.speaking" v-bind:title="$t('listen_story')" aria-hidden="true" @click="playAudio" class="fas fa-headphones menuicon"></i>
      <i v-if="this.speaking" @click="stopAudio" v-bind:title="$t('stop_audio')" class="fas fa-stop menuicon"></i>

      <!-- <audio v-if="this.audio" controls controlsList="nodownload"
       style="
            padding-right: 2vh;
            width: 34vw;
            height: 4vh;
            padding-top: 0px;
        ">
        <source :src="this.audio" type="audio/mpeg">
      </audio> -->

      <i @click="toggleLight"
         v-bind:title="$t('change_contrast')"
         :class="['fas', 'fa-lightbulb', 'menuicon', this.darkMode ? 'darkMode' : 'lightMode']"
         aria-hidden="false"
         aria-label="Toggle Light/Dark Mode"></i>
      <i @click="showSettingsModal"
         v-bind:title="$t('options')"
         :class="['fas', 'fa-cog', 'menuicon', this.darkMode ? 'darkMode' : 'lightMode']"
         aria-hidden="false"
         aria-label="Settings"></i>

    </div>
    <!-- <img v-if="isLoading" src="img/loading.gif" /> -->
    <Body :lineHeight="this.lineHeight" :fontSize="this.fontSize" :isLoading="this.isLoading"
          :authorText="this.authorText" :bodyText="bodyText" :titleText="this.titleText"
          :sendReaction=this.sendReaction
          :styles="this.darkMode ? 'darkMode' : 'lightMode'" />

          <Settings v-if="modalSettingsVisible" @close="hideSettingsModal"
          @font_plus="fontSize = Math.min(fontSize+2, 30);
                       lineHeight = Math.min(lineHeight+2, 35);"
          @font_less="fontSize = Math.max(fontSize-2, 16);
                      lineHeight = Math.max(lineHeight-2, 25);"
          >
    </Settings>

    <Recommendations v-if="modalRecommendationsVisible" @close="hideRecommendationsModal"
          :recommendations="this.recommendations"
          >
    </Recommendations>
  </div>
</template>

<script>
import Body from './components/Body.vue'
import Settings from '@/components/Settings'
import Recommendations from './components/Recommendations.vue';
import axios from 'axios';

export default {
  name: 'App',
  components: {
    Body,
    Settings,
    Recommendations
  },
  data() {
    return {
      authorText: "",
      bodyText: this.getData('bodyText') || "",
      titleText: this.getData('titleText') || this.$t('title_text'),
      storyId: this.getData('storyId') || 0,
      audio: this.getData('audio') ||  "",
      langValue: "es-ES",

      modalSettingsVisible: false,
      speaking: false,
      user_id: "",
      readingTime: 5,
      showReactions: this.getData('showReactions') || false,
      readingSelectorVisible: false,
      langSelectorVisible: false,

      darkMode: false,
      fontSize: 16,
      lineHeight: 25,
      isLoading: false,
      modalRecommendationsVisible: false,
      recommendations: []

    }
  },

  watch: {
    $route(newVal) {
      this.modalSettingsVisible = newVal.query?.settings || false
    }
  },
  
  methods: {
    toggleLight() {
      this.darkMode = !this.darkMode;
    },

    showSettingsModal() {
      this.modalSettingsVisible = true;
      this.$router.push({
        name : "App",
        query : {
          settings: true
        }
      })
    },

    hideSettingsModal() {
      this.modalSettingsVisible = false;
      this.$router.push({
        name : "App"
      })
    },

    showRecommendationsModal() {
      this.modalRecommendationsVisible = true;
      // this.$router.push({
      //   name : "App",
      //   query : {
      //     settings: true
      //   }
      // })
    },

    hideRecommendationsModal() {
      this.modalRecommendationsVisible = false;
      // this.$router.push({
      //   name : "App"
      // })
    },


    get_user_random_id() {
      function create_UUID(){
          var dt = new Date().getTime();
          var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
              var r = (dt + Math.random()*16)%16 | 0;
              dt = Math.floor(dt/16);
              return (c=='x' ? r :(r&0x3|0x8)).toString(16);
          });
          return uuid;
      }

      let uid = this.getData('user_random_id');

      if (uid=="") {
        uid = create_UUID();

        this.storeData('user_random_id', uid);

        this.user_id = uid
      }

      return uid;
    },

    storeData(key, value) {
      localStorage.setItem(key, value)
    },

    getData(key) {
      try {
        const value = localStorage.getItem(key)
        if(value !== null) {
          return value;
        }
      } catch(e) {
        // error reading value
        console.log(e);
      }

      return "";
    },


    playAudio() {
      this.speaker = new Audio(this.audio);
      this.speaker.addEventListener('ended', () => { this.stopAudio() });
      this.speaker.play()
      this.speaking = true;
    },

    stopAudio() {
      this.speaker.pause()
      this.speaking = false;
    },

    getStory() {
      if (!navigator.onLine) {
        alert(this.$t("reach_internet"));
        return;
      }

      if (this.speaking != "")
        this.speaker.pause();

      this.isLoading = true,
      this.firstRun = false,
      this.bodyText = "",
      this.authorText = "",
      this.titleText = "",
      this.showReactions = false,
      this.speaking = false


      let uid = this.get_user_random_id();
    
      if ( (this.langValue == "")) {
        alert("", this.$t("choose_language"));
        return
      }

      axios.post('https://us-central1-shortstories-292802.cloudfunctions.net/get_story',
                 {
                  lang: this.langValue,
                  uid: uid,
                  reading_time: this.readingTime
                })
                .then((response) => response.data)
                .then((json) => {
                  // Check if error
                  if (json == undefined) {
                    alert(this.$t("error_tryagain"));
                    return;
                  }
                  if ("error" in json) {
                    if (json["error"].indexOf("No story") > -1)
                      alert("", this.$t("nostory_tryagain"));
                    else
                      alert("", this.$t("error_tryagain"));
                    
                    this.isLoading = false
                  }
                  else {
                    this.titleText = json.title,
                    this.bodyText = json.body,
                    this.authorText = json.author,
                    this.storyId = json.id,
                    this.audio = json.audio,
                    this.showReactions = true,
                    
                    this.isLoading = false,

                    this.storeData('titleText', this.titleText);
                    this.storeData('bodyText', this.bodyText);
                    this.storeData('authorText', this.authorText);
                    this.storeData('storyId', this.storyId);
                    this.storeData('audio', this.audio);
                    this.storeData('showReactions', this.showReactions);
                  }
                })
                .catch((error) => {
                  console.error(error);
                  alert(this.$t("error_tryagain"));
                })
    },


    sendReaction(reaction) {
      let uid = this.get_user_random_id();
      //console.log("UID send reaction: " + uid);
      let story_id = this.storyId;
      this.bodyText = "";
      this.titleText = "";
      this.authorText = "";
      this.isLoading = true;
      this.showReactions = false;

      axios.post('https://us-central1-shortstories-292802.cloudfunctions.net/save_reaction',
                 {
                  reaction: reaction,
                  uid: uid,
                  story_id: story_id,
                  ui_lang: navigator.language
                 })
            .then((response) => response.data)
            .then((json) => {
              // Check if error
              if (json == undefined) {
                alert(this.$t("error_tryagain"));
                return;
              }
              this.isLoading = false;
              //this.scrollview.scrollTo({x: 0, y: 0, animated: false});
              // alert("", this.$t('after_save_reaction'))
              this.recommendations = json.recommendations;
              this.modalRecommendationsVisible = true;
            })
            .catch((error) => {
              console.error(error);
              
            });

    },
  },

  computed: {
  }
}
</script>

<style>
  @media (max-width: 768px) {
    .menuicon {
      font-size: 28px; /* Adjust for smaller screens */
      margin-right: 10px; /* Less space on the right in smaller screens */
    }
    
    .menu {
      padding: 10px; /* More padding on smaller screens */
      margin-top: 5px; /* Adjust top margin on smaller devices */
    }
    
    .titleText, .authorText, .baseText {
      padding-left: 10px; /* More padding for text on smaller screens */
      padding-right: 10px;
    }
  }

  #app {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    width: 100%;
    min-height: 100%;
    font-size: 16px;
  }

  .menuicon {
    font-size: min(8vw, 35px);
    margin-right: 2vw;
    cursor: pointer;
  }
  .menuicon {
    transition: color 0.3s ease; /* Smooth transition for color change */
  }

  .menuicon:hover, .menuicon:focus {
    color: #a2a2d0; /* Example color change on hover/focus */
    transform: scale(1.1); /* Slightly increase size on hover/focus */
  }

  .centered {
      text-align: center,
      
  }
  .darkMode {
    color: #E0E0E0;
    background-color: #454545;  /* #454545  00073F*/
    border-color: #ebebeb;
  }
  .lightMode {
    color: #00073F;
    background-color: #F0F4F8; /* #E0E0E0; */
    border-color: #cecece;
  }
  
  .inputContainer {
    margin-top: 2;
    padding-left: 15;
  }
  .list {
    flex: 1;
    margin-top: 5;
    padding-left: 15;
    margin-bottom: 10;
    
  }
  .scrollable {
    
    margin-top: 15;
    margin-right: 15
  }
  .menu {
    margin-right: 3.1vw;
    margin-left: 0.10vw;
    border-style: solid;
    border-color: #ebebeb;
    border-width: 0.2em;
    border-radius: 0.50em;
    float: right;
    padding: 1vw;
    margin-top: 2.5vw;

  }
  .columnItem {
    justify-content: space-between;
    margin-right: 5;
    margin-left: 5;
    margin-bottom: 5
  }
  .deleteAllButton {
    margin-right: 40
  }
  .baseText {
    flex: 1;
    font-family: "Roboto";
    text-align: justify;
    font-size: 16;
    line-height: 25;
    padding-top: 5;
  }
  .titleText {
  font-size: 24px; /* Slightly larger for emphasis */
  margin-bottom: 8px; /* Add space between the title and author */
}

.authorText {
  font-size: 14px; /* Increase readability */
  color: #666; /* Softer color for less emphasis */
  margin-bottom: 12px; /* Space before body text begins */
}
  .reactionButton {
    width: 45%,
  }
  .textButton {
    font-size: 40;
  }
  .headerButton {
    font-size: 16;
    font-family: "Roboto";
    border-width: 1;
    border-radius: 5;
    padding: 5;
  }

 
</style>
